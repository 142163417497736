
interface NavEntry {
    label: string;
    url: string;
    icon: any;
}

import {mapOutline, starOutline, personOutline, informationOutline, documentTextOutline, list} from "ionicons/icons";

export const NavigationList: Array<NavEntry> = [
    {
        label: 'Karte',
        url: '/karte',
        icon: mapOutline
    },
    {
        label: 'Konzept',
        url: '/konzept',
        icon: documentTextOutline
    },
    {
        label: 'News',
        url: '/blog',
        icon: starOutline
    },
    {
        label: 'Unterstützen',
        url: '/support',
        icon: personOutline
    },
    {
        label: 'Über',
        url: '/about',
        icon: informationOutline
    },
]