import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/home/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage

  },
  {
    path: '/karte',
    name: 'Karte',
    component: () => import('../views/map/MapPage.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/blog/BlogPage.vue')
  },
  {
    path: '/konzept',
    name: 'Konzept',
    component: () => import('../views/concept/ConceptPage.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/support/SupportPage.vue')
  },
  {
    path: '/about',
    name: 'Über',
    component: () => import('../views/about/AboutPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
