
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { mailOpen, logoTwitter, mapOutline } from "ionicons/icons";
import TransparentLogos from "@/components/generic/TransparentLogos.vue";
import InfoFooter from "@/components/generic/InfoFooter.vue";
import LayoutService from "../../lib/LayoutService";

export default defineComponent({
  name: "SupportPage",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonRow,
    TransparentLogos,
    InfoFooter
},
  setup() {
    const shareText = `
          Das hier ist ein Beispiel text!
      `;
    const twitterLink = `https://twitter.com/intent/tweet?text=${shareText}&hashtags=buga,bugaplus,radverkehr`;
    return {
      mailOpen,
      logoTwitter,
      mapOutline,
      twitterLink,
      LayoutService
    };
  },
});
