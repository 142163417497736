
  import { defineComponent } from "vue";
  import { NavigationList } from "../header/Navigation";
  import { menu, shareSocialSharp, bicycleOutline } from "ionicons/icons";
  import { menuController } from "@ionic/vue";
  import { Share } from "@capacitor/share";
  import { IonButton } from "@ionic/vue";
  
  export default defineComponent({
    name: "HeadMenu",
    components: { IonButton },
    setup() {
      return {
        NavigationList,
        menu,
        shareSocialSharp,
        bicycleOutline
      };
    },
    methods: {
      showMobileMenu() {
        menuController.open("mobileMenu");
      },
      share() {
        Share.share({
          title: "Radwegering um Wuppertal",
          text: `Schau dir das Konzept zum Radwegering um Wuppertal online an: https://bugaplus.info`,
        });
      },
    },
  });
  